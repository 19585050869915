<template>
  <div class="plans-block">
    <content-wrapper-block no-overflow>
      <div class="plans-block__header plans-header">
        <page-title>Планировки</page-title>
        <div class="plans-header__tabs">
          <ui-tab-bar v-model="quantity">
            <ui-tab-bar-tab
              v-for="(tab, i) in tabs"
              :key="i"
              :value="tab"
            >
              <template v-if="tab === RoomsEnum.S">Студия</template>
              <template v-else>{{ tab }}<span class="ui-tab_light">-к</span></template>
            </ui-tab-bar-tab>
          </ui-tab-bar>
        </div>
      </div>
      <div class="plans-block__body plans-body">
        <div class="plans-body__top">
          <div v-if="current" class="plans-body__info plan-info">
            <div class="plan-info__card plan-card">
              <div class="plan-card__label">Площадь,м²</div>
              <div
                v-if="areaFromTo.from !== areaFromTo.to"
                class="plan-card__value"
              >
                {{ areaFromTo.from }}-{{ areaFromTo.to }}
              </div>
              <div v-else class="plan-card__value">
                {{ areaFromTo.from }}
              </div>
            </div>
            <div class="plan-info__card plan-card">
              <div class="plan-card__label">Цена, <span>₽</span></div>
              <div class="plan-card__value">
                от {{ priceFrom[current.plan_type].toLocaleString("ru") }}
                <span class="plan-card__value_light">₽</span>
              </div>
            </div>
            <div class="plan-info__card plan-card">
              <div class="plan-card__label">Планировка</div>
              <div class="plan-card__value">
                {{ current.plan_type }}
              </div>
            </div>
            <div class="plan-info__card plan-card">
              <div class="plan-card__label">
                <img
                  class="apart-container__type-icon"
                  src="@/assets/images/icons/decoration_dark.svg"
                  alt=""
                  loading="lazy"
                />Отделка</div>
              <div v-if="current?.properties?.has_decoration" class="plan-card__value" >
                Отделка включена в стоимость
              </div>
              <div v-else class="plan-card__value">
                Возможна отделка
              </div>
            </div>
          </div>
          <div class="plans-body__slider">
            <swiper
              ref="swiper"
              class="plans-body__swiper"
              :options="{
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 0,
                pagination: {
                  el: '.swiper-plans-pagination',
                  clickable: true,
                },
              }"
              @slide-change="slideChange"
            >
              <swiper-slide
                v-for="type in types"
                :key="type.plan_type"
                class="apartments-view__slide"
              >
                <picture v-if="getPlanImage(type, true)">
                  <img
                    class="plans-body__slider-image"
                    :src="getPlanImage(type, true)"
                    alt="Планировка"
                  />
                </picture>
                <div class="plans-body__slider-apart">
                  {{ type.plan_type }}
                </div>
              </swiper-slide>
              <swiper-red-pagination
                slot="pagination"
                class="swiper-plans-pagination"
              />
            </swiper>
            <div class="plans-body__slider-footer slider-footer">
              <div
                v-show="Prev"
                class="slider-footer__button"
                @click="goToPrev"
              >
                <icon-arrow-left />
              </div>
              <div class="" />
              <div
                v-show="Next"
                class="slider-footer__button"
                @click="goToNext"
              >
                <icon-arrow-right />
              </div>
            </div>
          </div>
        </div>
        <div class="plans-body__content plans-content">
          <div class="plans-content__table">
            <ui-table
              class="plans-content__aparts apart-type-table"
              :data="tableData"
              @click-row="clickRow"
            >
              <ui-table-column label="Этаж" prop="floor" />
              <ui-table-column label="м²" prop="area" />
              <ui-table-column label="Тип" prop="type">
                <template slot="default"> Апартаменты </template>
              </ui-table-column>
              <ui-table-column label="№" prop="number" />
              <ui-table-column label="Цена, ₽" prop="amount">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.discount_cost.toLocaleString("ru") }}
                  </div>
                </template>
                <!-- <template slot-scope="scope">
                  {{ scope.row.amount.toLocaleString('ru') }}
                  <div
                    v-if="scope.row.action !== 0"
                    class="plans-content__action"
                  >
                    %
                  </div>
                </template> -->
              </ui-table-column>
            </ui-table>
            <router-link
              :to="{
                name: 'FilterPage',
                query: {
                  quantity: quantity,
                },
              }"
              @click.native="setNewApartmentsDecor(withDecor)"
            >
              <ui-button outline> Показать больше </ui-button>
            </router-link>
          </div>
          <div v-if="false" class="plans-content__banner plans-banner">
            <div class="plans-banner__body">
              <div class="plans-banner__title">
                Ваш дизайн-проект - в подарок!
              </div>
              <div class="plans-banner__description">
                При покупке до 20.07.2020. Заполните форму и узнайте
                подробности!
              </div>
            </div>
            <div class="plans-banner__footer">
              <ui-button>Получить</ui-button>
            </div>
          </div>
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import PageTitle from "@/components/elements/PageTitle";
import UiTabBar from "@/components/ui/UITabBar";
import UiTabBarTab from "@/components/ui/UITabBarTab";
import UiTable from "@/components/ui/UITable";
import UiTableColumn from "@/components/ui/UITableColumn";
import UiButton from "@/components/ui/UIButton";
import IconArrowLeft from "@/components/icons/IconArrowLeft";
import IconArrowRight from "@/components/icons/IconArrowRight";
// import IconHint from '@/components/icons/IconHint'
import SwiperRedPagination from "@/components/elements/SwiperRedPagination";
import { mapState, mapMutations } from "vuex";

const RoomsEnum = Object.freeze({ S: 'S' })

export default {
  name: "PlanTypesBlock",
  components: {
    SwiperRedPagination,
    // IconHint,
    IconArrowRight,
    IconArrowLeft,
    UiButton,
    UiTableColumn,
    UiTable,
    UiTabBarTab,
    UiTabBar,
    PageTitle,
    ContentWrapperBlock,
  },
  data: () => ({
    RoomsEnum,
    tabs: [],
    quantity: '',
    selected: 0,
    render: true,
    Prev: false,
    Next: true,
  }),
  watch: {
    quantity() {
      this.render = false;
      if (this.quantity !== "5") {
        this.Next = true;
      }

      this.select(0);

      if (this.$refs.swiper) {
        this.$refs.swiper.$swiper.slideTo(0);
      }

      this.$nextTick(() => {
        this.render = true;
      });
    },
    apartments: {
      handler() {
        this.initTabs()
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      apartments: (state) => state.apartments,
    }),
    tableData() {
      return this.filtered;
    },
    withDecor() {
      return this.types[this.selected].with_decoration;
    },
    filtered() {
      return this.apartments
        .filter((apartment) => {
          if (apartment.plan_type === this.current.plan_type) {
            return true;
          }

          return false;
        })
        .sort((a, b) => {
          return this.sortWay === "desc"
            ? parseFloat(a[this.sortName]) - parseFloat(b[this.sortName])
            : parseFloat(b[this.sortName]) - parseFloat(a[this.sortName]);
        });
    },
    currentRooms() {
      return (
        this.formatQuantity(this.current.quantity) +
        " - " +
        this.current.plan_type
      );
    },
    current() {
      if (this.types[this.selected]) {
        return this.types[this.selected];
      } else {
        return this.types[0];
      }
    },
    floorFromTo() {
      const fromTo = { from: 999, to: 1 };

      this.apartments.forEach((apartment) => {
        if (apartment.plan_type === this.current.plan_type) {
          if (fromTo.from > parseInt(apartment.floor))
            fromTo.from = parseInt(apartment.floor);
          if (fromTo.to < parseInt(apartment.floor))
            fromTo.to = parseInt(apartment.floor);
        }
      });

      return fromTo;
    },
    areaFromTo() {
      const fromTo = { from: 999, to: 1 };

      this.apartments.forEach((apartment) => {
        if (apartment.plan_type === this.current.plan_type) {
          if (fromTo.from > apartment.area) fromTo.from = apartment.area;
          if (fromTo.to < apartment.area) fromTo.to = apartment.area;
        }
      });

      return fromTo;
    },
    priceFrom() {
      const prices = [];

      this.apartments.forEach((apartment) => {
        if (
          !prices[apartment.plan_type] ||
          apartment.discount_cost < prices[apartment.plan_type]
        ) {
          prices[apartment.plan_type] = apartment.discount_cost;
        }
      });

      return prices;
    },
    types() {
      const types = [];

      this.apartments.forEach((apartment) => {
        if (!["available", "available_agent", "booked"].includes(apartment.status))
          return false;
        if (
          apartment.quantity === this.quantity.replace('S', 'Студия') ||
          apartment.quantity === "Евро-" + this.quantity
        ) {
          if (
            !types.find((element) => {
              return element.plan_type === apartment.plan_type;
            })
          ) {
            types.push(apartment);
          }
        }
      });

      return types;
    },
  },
  created() {
    this.$nextTick(() => {
      this.$refs.swiper.$swiper.on("slideNextTransitionStart", () => {
        this.Prev = true;
      });
      this.$refs.swiper.$swiper.on("slidePrevTransitionStart", () => {
        this.Next = true;
      });
      this.$refs.swiper.$swiper.on("reachEnd", () => {
        this.Next = false;
      });
      this.$refs.swiper.$swiper.on("reachBeginning", () => {
        this.Prev = false;
      });
    });
  },
  methods: {
    ...mapMutations({
      setNewApartmentsDecor: "SET_NEW_APARTMENTS_DECOR",
    }),
    initTabs() {
      this.apartments.forEach((item) => {
        const tab = item.layout.room_count.replace('E', '')
        if (!this.tabs.includes(tab)) this.tabs.push(tab)
      })
      this.tabs.sort((a, b) => {
        if (a === RoomsEnum.S) {
          return -1
        } else if (b === RoomsEnum.S) {
          return 1
        }
        return a - b
      })
      this.quantity = this.tabs[0]
    },
    goToNext() {
      this.$refs.swiper.$swiper.updateSlides();
      this.$refs.swiper.$swiper.slideNext();
    },
    goToPrev() {
      this.$refs.swiper.$swiper.updateSlides();
      this.$refs.swiper.$swiper.slidePrev();
    },
    select(index) {
      if (index > -1) {
        this.selected = index;
      }
    },
    slideChange() {
      this.select(this.$refs.swiper.$swiper.activeIndex);
    },
    click(index) {
      this.select(index);
      this.$refs.swiper.$swiper.slideTo(index);
    },
    clickRow(row) {
      this.$router.push({
        name: "ApartmentPage",
        params: {
          korp: row.korpus,
          sec: row.section,
          floor: row.floor,
          fnumb: row.number_on_floor,
          numb: row.number,
          quantity: this.getQuantity(row.quantity),
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.plans-block {
  &__whitebox {
    height: 80%;
    position: absolute;
    top: 10%;
    right: 0;
    @media screen and (min-width: $screen-mini) {
      right: 10px;
    }
  }
  &__body {
    .plans-body {
      &__swiper {
        overflow: visible;

        .swiper-slide {
          opacity: 0.3;
          transform: scale(0.8) translateY(-30px) translateX(-75px);
          transition: all 300ms ease-out;

          &-active {
            opacity: 1;
            transform: scale(1);

            .plans-body__slider-apart {
              display: none;
            }
          }

          &-prev {
            transform: scale(0.9) translateY(0px) translateX(10px);

            @media screen and (min-width: $screen-sm) {
              transform: unset;
            }
          }

          &-next {
            transform: scale(0.9) translateY(0px) translateX(-10px);
            opacity: 0.5;

            @media screen and (min-width: $screen-sm) {
              transform: scale(0.8) translateY(-30px) translateX(-15px);
            }
          }
        }
      }
    }
  }
  .slider-footer {
    &__button {
      cursor: pointer;

      circle,
      path {
        stroke: #e14313;
      }

      &:hover {
        circle,
        path {
          stroke: #000000;
        }
      }
    }
  }

  .apart-type-table {
    .ui-table__body-cell {
      width: 25%;

      font-size: 10px;
      margin: 8px 5px 7px 8px;
      justify-content: center;

      &:nth-child(1) {
        width: 30px;
      }
      &:nth-child(2) {
        width: 30px;
      }
      &:nth-child(3) {
        width: 65px;
      }
      &:nth-child(4) {
        width: 65px;
      }

      @media screen and (min-width: $screen-xxs) {
        font-size: 11px;
        margin: 13px 5px 13px 17px;

        &:nth-child(1) {
          width: 20px;
        }
        &:nth-child(2) {
          width: 60px;
        }
        &:nth-child(3) {
          width: 25%;
        }
      }
      @media screen and (min-width: $screen-mini) {
        font-size: 14px;
        margin-left: 17px;
      }
    }

    .ui-table__row {
      cursor: pointer;
      &:hover {
        background-color: #e14313;
        .ui-table__body-cell {
          color: #ffffff;
        }
      }
    }

    .ui-table__header-cell {
      width: 25%;
      font-size: 10px;
      margin: 8px 5px 7px 8px;
      justify-content: center;

      &:nth-child(1) {
        width: 30px;
      }
      &:nth-child(2) {
        width: 30px;
      }
      &:nth-child(3) {
        width: 65px;
      }
      &:nth-child(4) {
        width: 65px;
      }

      @media screen and (min-width: $screen-xs) {
        font-size: 14px;
        margin: 13px 5px 0 17px;

        &:nth-child(1) {
          width: 20px;
        }
        &:nth-child(2) {
          width: 60px;
        }
        &:nth-child(3) {
          width: 25%;
        }
      }
    }
  }

  .swiper-plans-pagination {
    margin-top: 20px;
    display: flex;

    @media screen and (min-width: $screen-sm) {
      margin-top: 0;
    }

    @media screen and (min-width: $screen-md) {
      display: none;
    }
  }
}
</style>

<style scoped lang="scss">
.plans-block {
  overflow: hidden;
  background-color: #f4f4f4;
  padding-bottom: 50px;

  &__header,
  .plans-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;
      margin-bottom: 0;
    }

    &__tabs {
      align-items: center;
      display: flex;

      @media screen and (min-width: $screen-sm) {
        margin-left: 30px;
      }
      .ui-tab {
        display: flex;
        align-items: baseline;
        font-weight: 700;
        font-size: 18px;

        @media screen and (min-width: $screen-xs) {
          font-size: 20px;
        }

        &_light {
          font-size: 10px;
          font-weight: 400;

          @media screen and (min-width: $screen-xs) {
            font-size: 12px;
          }
        }
      }
    }
  }

  &__body,
  .plans-body {
    &__top {
      display: flex;
      flex-direction: column-reverse;

      @media screen and (min-width: $screen-sm) {
        flex-direction: row;
      }
    }

    &__swiper {
      margin: 0 25px;

      @media screen and (min-width: $screen-sm) {
        margin: auto;
      }
    }

    &__info,
    .plan-info {
      // Calc centered
      width: 100%;
      background-color: #f4f4f4;
      z-index: 5;
      position: relative;
      display: flex;
      margin-top: 30px;
      flex-wrap: wrap;

      @media screen and (min-width: $screen-sm) {
        margin-top: 0;
        width: calc((100vw - 380px) / 2);
        display: block;
      }

      @media screen and (min-width: $screen-md) {
        width: 250px;
      }

      &:after {
        display: none;
        position: absolute;
        content: " ";
        height: 100%;
        width: 1500px;
        background-color: #f4f4f4;
        right: 0;
        top: 0;

        @media screen and (min-width: $screen-sm) {
          display: block;
          right: calc((100vw - 380px) / 2);
        }

        @media screen and (min-width: $screen-md) {
          right: 250px;
        }
      }

      &__card,
      .plan-card {
        font-family: $font-family-bebas;
        margin-bottom: 12px;
        color: #18181b;
        width: 50%;
        &:nth-child(2n + 1) {
          margin-right: auto;
        }
        @media screen and (min-width: $screen-sm) {
          flex: 1;
          width: auto;
          margin-bottom: 24px;
        }

        img {
          display: block;
          width: 12px;
          margin-right: 4px;
        }

        &__label {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 132.5%;
          letter-spacing: 0.18em;
          opacity: 0.5;
          margin-bottom: 8px;
          display: flex;

          @media screen and (min-width: $screen-sm) {
            font-size: 14px;
          }
        }
        &__value {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 98%;
          letter-spacing: 0.05em;

          @media screen and (min-width: $screen-sm) {
            font-size: 24px;
          }

          &_light {
            display: none;
            font-weight: 500;

            @media screen and (min-width: $screen-sm) {
              display: inline;
              font-family: "PT Sans Caption", sans-serif;
            }
          }
        }
      }
    }

    &__slider {
      width: 100%;

      @media screen and (min-width: $screen-sm) {
        width: 379px;
      }

      &-image {
        width: 100%;
        height: 300px;
        object-fit: contain;
        cursor: grab;

        @media screen and (min-width: $screen-sm) {
          // width: 100%;
        }
      }

      &-footer {
        display: none;
        justify-content: space-between;
        margin-top: -15px;
        z-index: 6;
        position: relative;

        @media screen and (min-width: $screen-md) {
          display: flex;
        }
      }

      &-apart {
        display: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 132.5%;
        letter-spacing: 0.18em;
        text-transform: uppercase;
        color: #18181b;
        opacity: 0.5;
        margin-top: 15px;

        @media screen and (min-width: $screen-sm) {
          display: block;
        }
      }
    }

    &__content,
    .plans-content {
      display: flex;

      padding-top: 20px;
      border-top: 1px solid #dedede;
      margin-top: 20px;

      @media screen and (min-width: $screen-sm) {
        border-top: unset;
        padding-top: 0;
      }

      @media screen and (min-width: $screen-lg) {
        margin-top: 70px;
      }

      &__action {
        background-color: #e14313;
        color: #ffffff;
        padding: 5px 6px 2px 8px;
        right: -11px;
        position: absolute;
        font-family: $font-family-bebas;
        font-weight: 700;
        font-size: 18px;
        line-height: 115%;
      }

      &__aparts {
        margin-bottom: 30px;
        ::v-deep {
          .ui-table__header,
          .ui-table__row {
            padding-right: 25px;
            @media screen and (min-width: $screen-xs) {
              padding-right: 43px;
            }
            @media screen and (min-width: $screen-mini) {
              padding-right: 53px;
            }
          }
        }
      }

      &__table {
        width: 100%;

        @media screen and (min-width: $screen-md) {
          width: 570px;
          margin-right: 130px;
        }
      }

      &__banner,
      .plans-banner {
        display: none;

        @media screen and (min-width: $screen-md) {
          padding: 40px;
          background-color: #18181b;
          color: #ffffff;
          flex: 1;
          font-family: $font-family-bebas;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        &__title {
          font-weight: 400;
          font-size: 36px;
          line-height: 105%;
          letter-spacing: 0.05em;
          margin-bottom: 17px;
        }

        &__description {
          font-weight: 400;
          font-size: 14px;
          line-height: 132.5%;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: #ffffff;
          opacity: 0.6;
          margin-bottom: 30px;
        }
      }
    }
  }
}
</style>
