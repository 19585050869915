var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "plans-block" },
    [
      _c("content-wrapper-block", { attrs: { "no-overflow": "" } }, [
        _c(
          "div",
          { staticClass: "plans-block__header plans-header" },
          [
            _c("page-title", [_vm._v("Планировки")]),
            _c(
              "div",
              { staticClass: "plans-header__tabs" },
              [
                _c(
                  "ui-tab-bar",
                  {
                    model: {
                      value: _vm.quantity,
                      callback: function ($$v) {
                        _vm.quantity = $$v
                      },
                      expression: "quantity",
                    },
                  },
                  _vm._l(_vm.tabs, function (tab, i) {
                    return _c(
                      "ui-tab-bar-tab",
                      { key: i, attrs: { value: tab } },
                      [
                        tab === _vm.RoomsEnum.S
                          ? [_vm._v("Студия")]
                          : [
                              _vm._v(_vm._s(tab)),
                              _c("span", { staticClass: "ui-tab_light" }, [
                                _vm._v("-к"),
                              ]),
                            ],
                      ],
                      2
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "plans-block__body plans-body" }, [
          _c("div", { staticClass: "plans-body__top" }, [
            _vm.current
              ? _c("div", { staticClass: "plans-body__info plan-info" }, [
                  _c("div", { staticClass: "plan-info__card plan-card" }, [
                    _c("div", { staticClass: "plan-card__label" }, [
                      _vm._v("Площадь,м²"),
                    ]),
                    _vm.areaFromTo.from !== _vm.areaFromTo.to
                      ? _c("div", { staticClass: "plan-card__value" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.areaFromTo.from) +
                              "-" +
                              _vm._s(_vm.areaFromTo.to) +
                              " "
                          ),
                        ])
                      : _c("div", { staticClass: "plan-card__value" }, [
                          _vm._v(" " + _vm._s(_vm.areaFromTo.from) + " "),
                        ]),
                  ]),
                  _c("div", { staticClass: "plan-info__card plan-card" }, [
                    _c("div", { staticClass: "plan-card__label" }, [
                      _vm._v("Цена, "),
                      _c("span", [_vm._v("₽")]),
                    ]),
                    _c("div", { staticClass: "plan-card__value" }, [
                      _vm._v(
                        " от " +
                          _vm._s(
                            _vm.priceFrom[_vm.current.plan_type].toLocaleString(
                              "ru"
                            )
                          ) +
                          " "
                      ),
                      _c("span", { staticClass: "plan-card__value_light" }, [
                        _vm._v("₽"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "plan-info__card plan-card" }, [
                    _c("div", { staticClass: "plan-card__label" }, [
                      _vm._v("Планировка"),
                    ]),
                    _c("div", { staticClass: "plan-card__value" }, [
                      _vm._v(" " + _vm._s(_vm.current.plan_type) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "plan-info__card plan-card" }, [
                    _c("div", { staticClass: "plan-card__label" }, [
                      _c("img", {
                        staticClass: "apart-container__type-icon",
                        attrs: {
                          src: require("@/assets/images/icons/decoration_dark.svg"),
                          alt: "",
                          loading: "lazy",
                        },
                      }),
                      _vm._v("Отделка"),
                    ]),
                    _vm.current?.properties?.has_decoration
                      ? _c("div", { staticClass: "plan-card__value" }, [
                          _vm._v(" Отделка включена в стоимость "),
                        ])
                      : _c("div", { staticClass: "plan-card__value" }, [
                          _vm._v(" Возможна отделка "),
                        ]),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "plans-body__slider" },
              [
                _c(
                  "swiper",
                  {
                    ref: "swiper",
                    staticClass: "plans-body__swiper",
                    attrs: {
                      options: {
                        slidesPerView: "auto",
                        centeredSlides: true,
                        spaceBetween: 0,
                        pagination: {
                          el: ".swiper-plans-pagination",
                          clickable: true,
                        },
                      },
                    },
                    on: { "slide-change": _vm.slideChange },
                  },
                  [
                    _vm._l(_vm.types, function (type) {
                      return _c(
                        "swiper-slide",
                        {
                          key: type.plan_type,
                          staticClass: "apartments-view__slide",
                        },
                        [
                          _vm.getPlanImage(type, true)
                            ? _c("picture", [
                                _c("img", {
                                  staticClass: "plans-body__slider-image",
                                  attrs: {
                                    src: _vm.getPlanImage(type, true),
                                    alt: "Планировка",
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "plans-body__slider-apart" },
                            [_vm._v(" " + _vm._s(type.plan_type) + " ")]
                          ),
                        ]
                      )
                    }),
                    _c("swiper-red-pagination", {
                      staticClass: "swiper-plans-pagination",
                      attrs: { slot: "pagination" },
                      slot: "pagination",
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "plans-body__slider-footer slider-footer" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.Prev,
                            expression: "Prev",
                          },
                        ],
                        staticClass: "slider-footer__button",
                        on: { click: _vm.goToPrev },
                      },
                      [_c("icon-arrow-left")],
                      1
                    ),
                    _c("div", {}),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.Next,
                            expression: "Next",
                          },
                        ],
                        staticClass: "slider-footer__button",
                        on: { click: _vm.goToNext },
                      },
                      [_c("icon-arrow-right")],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "plans-body__content plans-content" }, [
            _c(
              "div",
              { staticClass: "plans-content__table" },
              [
                _c(
                  "ui-table",
                  {
                    staticClass: "plans-content__aparts apart-type-table",
                    attrs: { data: _vm.tableData },
                    on: { "click-row": _vm.clickRow },
                  },
                  [
                    _c("ui-table-column", {
                      attrs: { label: "Этаж", prop: "floor" },
                    }),
                    _c("ui-table-column", {
                      attrs: { label: "м²", prop: "area" },
                    }),
                    _c(
                      "ui-table-column",
                      { attrs: { label: "Тип", prop: "type" } },
                      [
                        _c("template", { slot: "default" }, [
                          _vm._v(" Апартаменты "),
                        ]),
                      ],
                      2
                    ),
                    _c("ui-table-column", {
                      attrs: { label: "№", prop: "number" },
                    }),
                    _c("ui-table-column", {
                      attrs: { label: "Цена, ₽", prop: "amount" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.discount_cost.toLocaleString(
                                        "ru"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "FilterPage",
                        query: {
                          quantity: _vm.quantity,
                        },
                      },
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.setNewApartmentsDecor(_vm.withDecor)
                      },
                    },
                  },
                  [
                    _c("ui-button", { attrs: { outline: "" } }, [
                      _vm._v(" Показать больше "),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            false
              ? _c(
                  "div",
                  { staticClass: "plans-content__banner plans-banner" },
                  [
                    _c("div", { staticClass: "plans-banner__body" }, [
                      _c("div", { staticClass: "plans-banner__title" }, [
                        _vm._v(" Ваш дизайн-проект - в подарок! "),
                      ]),
                      _c("div", { staticClass: "plans-banner__description" }, [
                        _vm._v(
                          " При покупке до 20.07.2020. Заполните форму и узнайте подробности! "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "plans-banner__footer" },
                      [_c("ui-button", [_vm._v("Получить")])],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }